export function formatPrice(price: string | number) {
	let number;

	if (typeof price === "string") {
		number = parseFloat(price);
	} else {
		number = price;
	}

	return new Intl.NumberFormat("nl-NL", {
		style: "currency",
		currency: "EUR",
	}).format(number);
}
