export const freeShippingLimit: number = 70;

const getShipping = (totalString: string | 0) => {
	const total = parseFloat(totalString.toString());

	const defaultShipping = {
		method_title: "Flat rate",
		method_id: "flat_rate",
		instance_id: "1",
		meta_data: [],
	};

	// if (count > 6) {
	// 	return {
	// 		...defaultShipping,
	// 		total: "14.7933884298",
	// 		total_tax: "3.1066115702",
	// 		taxes: [
	// 			{
	// 				id: 1,
	// 				total: "3.1066115702",
	// 				subtotal: "",
	// 			},
	// 		],
	// 	};
	// }

	if (parseFloat(total.toString()) > freeShippingLimit) {
		return {
			...defaultShipping,
			total: "0",
			total_tax: "0",
			taxes: [],
		};
	}

	const totalShippingCost = 6.95;
	const shippingCostWithoutTax = totalShippingCost / 1.21; // 5,7438016529
	const shippingTax = totalShippingCost - shippingCostWithoutTax;

	return {
		...defaultShipping,
		total: shippingCostWithoutTax.toFixed(2),
		total_tax: shippingTax.toFixed(2),
		taxes: [
			{
				id: 1,
				total: shippingTax.toString(),
				subtotal: "",
			},
		],
	};
};

export default getShipping;
