import { ComponentProps } from "react";

interface Props extends Pick<ComponentProps<"img">, "className"> {
	tasteProfile?: string;
	priority: "low" | "high";
}

export function ResultIllustration({
	tasteProfile,
	className,
	priority,
}: Props) {
	if (!tasteProfile) return null;
	tasteProfile = tasteProfile.replace(/\s/g, "");

	return (
		<img
			className={className}
			sizes="(max-width: 1023px) 400w, 33.333vw"
			src={`/taste-styles/${tasteProfile}.svg`}
			width={200}
			height={200}
			role="presentation"
			alt=""
			loading={priority === "low" ? "lazy" : "eager"}
		/>
	);
}
