import { RefObject, PropsWithChildren } from "react";
import * as AlertDialog from "@radix-ui/react-alert-dialog";
import { FocusOn } from "react-focus-on";
import cx from "classnames";

import { Button, IconButton } from "@/components/Button";
import { Heading, Text } from "@/components/Typography";
import HtmlParser from "@/components/HtmlParser";

import s from "./ConfirmationDialog.module.scss";

interface Props {
	open: boolean;
	onClose: () => void;
	onConfirm: () => void;
	onCancel: () => void;
	portal?: RefObject<HTMLDivElement>;
	title: string;
	text?: string;
	buttonCancel?: string;
	buttonAccept: string;
	showCookieSettings?: boolean;
	isAddToCartButton?: boolean;
}

export function ConfirmationDialog({
	open,
	onClose,
	onConfirm,
	onCancel,
	portal,
	title,
	text,
	buttonAccept,
	buttonCancel,
	isAddToCartButton = false,
	children,
}: PropsWithChildren<Props>) {
	return (
		<AlertDialog.Root open={open} onOpenChange={onClose}>
			<AlertDialog.Portal container={portal?.current}>
				<FocusOn onClickOutside={onClose} onEscapeKey={onClose}>
					<AlertDialog.Overlay className={s.dialog__overlay}>
						<AlertDialog.Content className={s.dialog__content}>
							<div className={s.dialog__button__close}>
								<IconButton icon="close" label="sluiten" onClick={onClose} />
							</div>
							<div className={s.dialog__header}>
								<AlertDialog.Title asChild>
									<Heading as="h2" sizeMobile="md" sizeDesktop="md">
										{title}
									</Heading>
								</AlertDialog.Title>
							</div>
							<AlertDialog.Description asChild>
								{text && (
									<Text>
										<HtmlParser content={text}></HtmlParser>
									</Text>
								)}
							</AlertDialog.Description>
							{children}
							<div
								className={cx(s.dialog__buttons, {
									[s.dialog__isAddToCartButton]: isAddToCartButton,
								})}
							>
								{buttonAccept && (
									<div>
										<AlertDialog.Action asChild>
											<Button
												rank="primary"
												icon="arrow-right"
												onClick={onConfirm}
											>
												{buttonAccept}
											</Button>
										</AlertDialog.Action>
									</div>
								)}
								{buttonCancel && (
									<div>
										<AlertDialog.Cancel asChild>
											<Button
												rank="secondary"
												onClick={onCancel}
												icon={isAddToCartButton ? "arrow-right" : undefined}
											>
												{buttonCancel}
											</Button>
										</AlertDialog.Cancel>
									</div>
								)}
							</div>
						</AlertDialog.Content>
					</AlertDialog.Overlay>
				</FocusOn>
			</AlertDialog.Portal>
		</AlertDialog.Root>
	);
}
