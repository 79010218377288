import create from "zustand";
import { persist } from "zustand/middleware";
import { ShoppingCartState } from "@/models/store";
import { Wine } from "@/models";
import { GenericID } from "@/models/api";

export const useStore = create<ShoppingCartState>(
	persist(
		(set, _) => ({
			items: [],
			entities: {},
			size: 3,
			add: (product) => set((state) => addToState(state, product)),
			remove: (id) => set((state) => removeFromState(state, id)),
			setSize: (newSize) => set((state) => onChangeSize(state, newSize)),
		}),
		{
			name: "shoppingCart",
			getStorage: () => localStorage,
		}
	)
);

export const useShoppingCartSize = (): [
	ShoppingCartState["size"],
	(size: ShoppingCartState["size"]) => void
] => {
	const store = useStore();

	return [store.size, store.setSize];
};

export const useShoppingCartCount = (): number => {
	const store = useStore();

	return store.items.length || 0;
};

export const useShoppingCartTotal = () => {
	const store = useStore();

	const prices = store.items.map((item) => {
		const entity = store.entities[item];
		return parseFloat(entity.price.replace(/,/g, "."));
	});

	if (prices.length) return prices.reduce((a, b) => a + b).toFixed(2);

	return 0;
};

export const useShoppingCartItems = (): { item: Wine; quantity: number }[] => {
	const store = useStore();

	return Object.values(store.entities).map((entity) => {
		const quantity = store.items.filter((id) => id === entity.id).length;

		return { item: entity, quantity };
	});
};

export const useMiniShoppingCart = () => {
	const store = useStore();

	const itemsArray = Array.from(Array(store.size));

	const finalfinalArray = itemsArray.map((_, index) => {
		if (store.items[index]) {
			const id = store.items[index];
			const entity = store.entities[id];
			return {
				id: entity.id,
				img: entity.featured_media,
			};
		}

		return index;
	});

	return finalfinalArray;
};

function addToState(state: ShoppingCartState, product: Wine) {
	let newSize: 3 | 6 | 12 | null = null;

	if (state.items.length === state.size) {
		switch (state.size) {
			case 12:
			case 6:
				newSize = 12;
				break;
			case 3:
				newSize = 6;
				break;
			default:
				newSize = 3;
				break;
		}
	}

	return {
		items: [...state.items, product.id],
		entities: {
			...state.entities,
			[product.id]: product,
		},
		size: newSize || state.size,
	};
}

function removeFromState(state: ShoppingCartState, id: GenericID) {
	const { entities, items } = state;
	const lastIndex = items.lastIndexOf(id);

	items.splice(lastIndex, 1);

	const count = items.filter((i) => i === id).length;

	if (count === 0) {
		try {
			delete entities[id];
		} catch (e) {
			console.error(e);
		}
	}

	return {
		items,
		entities,
	};
}

function onChangeSize(
	state: ShoppingCartState,
	newSize: ShoppingCartState["size"]
) {
	if (newSize > state.size) {
		return {
			size: newSize,
			items: state.items,
			entities: state.entities,
		};
	}

	const newItems = state.items.slice(0, newSize);

	const { entities } = state;
	Object.keys(entities).forEach((id) => {
		if (!newItems.includes(parseInt(id, 10))) {
			delete entities[id];
		}
	});

	return {
		size: newSize,
		items: newItems,
		entities,
	};
}
