import { ComponentProps } from "react";

interface Props extends Pick<ComponentProps<"img">, "className"> {
	image?: { [key: string]: string };
	priority: "low" | "high";
}

export function ResultBottle({ image, className, priority }: Props) {
	if (!image) return null;

	const img_240 = image["bottle_69x240"];
	const img_290 = image["bottle_84x290"];
	const img_380 = image["bottle_110x380"];
	const img_460 = image["bottle_133x460"];
	const img_570 = image["bottle_165x570"];
	const img_650 = image["bottle_188x650"];
	const img_760 = image["bottle_220x760"];

	return (
		<img
			className={className}
			srcSet={`${img_240} 240w, ${img_290} 290w, ${img_380} 380w, ${img_460} 460w, ${img_570} 570w, ${img_650} 650w, ${img_760} 760w`}
			src={img_380}
			width={69}
			height={240}
			alt=""
			loading={priority === "low" ? "lazy" : "eager"}
		/>
	);
}
