import { Heading } from "@/components/Typography";
import { Wine } from "@/models";
import { ResultIllustration } from "./ResultIllustration";
import { ResultBottle } from "./ResultBottle";
import Link from "next/link";
import { IntegratedWine } from "@/models/wine";
import { formatPrice } from "@/utils/formatPrice";
import { useRouter } from "next/router";
import { Button, LinkButton } from "@/components/Button";
import {
	useShoppingCartItems,
	useShoppingCartTotal,
	useStore,
} from "@/hooks/store";
import getShipping from "@/utils/getShipping";
import { ConfirmationDialog } from "@/components/Dialogs/ConfirmationDialog";
import { useRef, useState } from "react";
import { LineItem } from "@/models/orders";

import s from "./ResultItem.module.scss";

interface Props {
	wine: Wine | IntegratedWine | any;
}

export function ResultItem({ wine }: Props) {
	const router = useRouter();
	const { add, items } = useStore();
	const total = useShoppingCartTotal();
	const cartItems = useShoppingCartItems();
	const [showInfoDialog, setShowInfoDialog] = useState<boolean>(false);
	const handleAddToCart = () => {
		if (items.length >= 11) {
			setShowInfoDialog(true);
		}
		// safety check bc it turned out to be null sometimes.
		console.log("wine", wine);
		if (wine != null) add(wine);
	};
	const portalRef = useRef<HTMLDivElement | null>(null);

	const onConfirmOrder = async () => {
		const lineItems: Partial<LineItem>[] = cartItems.map(
			({ item, quantity }) => ({
				product_id: item.id,
				quantity,
			})
		);

		const body = JSON.stringify({
			line_items: lineItems,
			shipping_lines: [getShipping(total)],
		});

		const response = await fetch("/api/order", {
			method: "POST",
			body,
		});

		const order = await response.json();

		router.push(`/checkout/${order.id}`);
	};

	return (
		<div className={s.item} ref={portalRef}>
			<Link
				scroll={false}
				href={{
					pathname: wine.link,
					query: router.query,
				}}
			>
				<a className={s.item__link}>
					<div className={s.item__images}>
						{wine.acf.taste_profile ? (
							<ResultIllustration
								className={s.item__images__bg}
								tasteProfile={wine.acf.taste_profile.name}
								priority="high"
							/>
						) : (
							// <Skeleton height="100%" />
							<></>
						)}
						<ResultBottle
							className={s.item__images__bottle}
							image={wine.featured_image}
							priority="high"
						/>
					</div>
				</a>
			</Link>
			<div className={s.item__content}>
				<Heading
					className={s.item__h2}
					as="h2"
					sizeMobile="sm"
					sizeDesktop="sm"
				>
					{wine.acf.taste_profile.name}
				</Heading>
				<Heading
					className={s.item__h4}
					as="h4"
					sizeMobile="xxs"
					sizeDesktop="xxs"
					dangerouslySetInnerHTML={{ __html: wine.name }}
				/>
				<Heading
					className={s.item__h4}
					as="h4"
					sizeMobile="xxs"
					sizeDesktop="xxs"
				>
					{wine.acf.color.name}
				</Heading>
				<Heading
					className={s.item__price}
					as="h3"
					sizeMobile="price"
					sizeDesktop="price"
				></Heading>
			</div>
			<div className={s.item__buttons}>
				<LinkButton
					rank="secondary"
					href={{
						pathname: wine.link,
						query: router.query,
					}}
				>
					Meer info
				</LinkButton>
				<Button
					className={s.item__button__price}
					rank="secondary"
					icon="plus"
					onClick={handleAddToCart}
					disabled={items.length >= 12}
				>
					{formatPrice(wine.price)}
				</Button>
			</div>
			<ConfirmationDialog
				open={showInfoDialog}
				onConfirm={() => {
					setShowInfoDialog(false);
					onConfirmOrder();
				}}
				onClose={() => setShowInfoDialog(false)}
				onCancel={() => {
					setShowInfoDialog(false);
				}}
				portal={portalRef}
				title="In the pocket!"
				text="Je hebt nu 12 flessen, bestellen maar!"
				buttonAccept="Bestellen"
				buttonCancel=""
				isAddToCartButton={true}
			/>
		</div>
	);
}
